import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

import { environment } from '@env/environment';

import { AppInjector } from '@app/bootstrap/services/app-injector.service';
import { DefaultTranslationsService } from './default-translations.service';


export class OneMissingTranslationHandler implements MissingTranslationHandler {
  private defaultTranslationsService: DefaultTranslationsService;

  handle(params: MissingTranslationHandlerParams): string {
    this.defaultTranslationsService = AppInjector.getInjector().get(DefaultTranslationsService);
    if (this.defaultTranslationsService.defaultTranslations && this.getTranslation(this.defaultTranslationsService.defaultTranslations, params.key) !== undefined) {
      const translation = params.translateService.getParsedResult(this.defaultTranslationsService.defaultTranslations, params.key, params.interpolateParams);
      return `${this.getPrefix()}${translation}`;
    } else {
      return `${this.getPrefix()}${params.key}`;
    }
  }

  // get deep level of property of an object eg: getTranslation({a: {b: 2}}, 'a.b') => 2
  private getTranslation (obj, keys: string): string {
    return keys?.split('.').reduce((cur, key) => cur == undefined ? undefined : cur[key], obj);
  }

  private getPrefix(): string {
    const environmentLabel = environment.environmentLabel.toLowerCase();
    return (environmentLabel === 'stg' || environmentLabel === 'prod') ? '' : '*** ';
  }
}

