import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { environment } from '@env/environment';

import { HttpBaseResponse } from '@app/one-core/common/http/http-base-response';
import { OneHttpClient } from '@app/one-core/common/http/one-httpclient-factory';
import { FeatureSettings } from '@app/one-models/models/feature-settings';


export enum FeatureName {
    pmSolver = 'PLA',
    idp = 'idp',
    smtp = 'smtp',
    opticatPiesDigitalAsset = 'opticat-pies-digital-asset',
    importTecDocLinkages = 'import-tecdoc-linkages',
    tecDocApplicationChanges = 'tecdoc-application-changes',
    tecDocVIO = 'tecdoc-vio',
    inheritance = 'inheritance',
    marketCopy = 'market-copy',
    reportingLinkageReference = 'reporting-linkage-reference',
    autocareIdp = 'autocare-idp',
    importAcesLinkages = 'import-aces-linkages',
    dqm = 'dqm',
    unreleasedFeatures = 'unreleased-features', 
    tecdocArticleLinkagesAttributeFilters = 'tecdoc-article-linkages-attribute-filters',
    importAcesVio = 'import-aces-vio',
    acesVio = 'aces-vio',
    reportingLinkagesExport = 'reporting-linkages-export',
    reportingLinkagesAcesExport = 'reporting-linkages-aces-export',
    articleAdditionalInformation = 'article-additional-information',
    reportingTextBlocksExport = 'text-blocks-export',
    qdb = 'qdb',
    reportingSupersessionExtensions = 'reporting-supersession-extensions',
    attributesWithPublishingCountries = 'attributes-with-publishing-countries',
    oemMakeLookup = 'oem-make-lookup',
    translationManager = 'translation-manager',
  }

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {

  private api = environment.services.portalApi;
  private featuresEndpoint = '/features';

  private $featuresCache: Observable<{[featureName: string]: FeatureSettings}>;

  constructor(private http: OneHttpClient) {
  }
  
  getFeaturesConfiguration(): Observable<{[featureName: string]: FeatureSettings}> {

    if (!this.$featuresCache)
    {
      this.$featuresCache = this.http.get<HttpBaseResponse<{[featureName: string]: FeatureSettings}>>(`${this.api}${this.featuresEndpoint}/features-configuration`)
      .pipe(
        map(response => response.data),
        shareReplay(1)
      );
    }

    return this.$featuresCache;
  }
}
