import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';


export enum AppEvent {
  UserProfileUpdated = 'USERPROFILE_UPDATED',
}

class BroadcastMessage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public event: AppEvent, public payload: any) { }
}

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  private eventBrocker = new Subject<BroadcastMessage>();

  // Listen to an AppEvent filtered by the event name
  on<T>(event: AppEvent): Observable<T> {
    return this.eventBrocker.pipe(
      filter((request: BroadcastMessage) => request.event === event),
      map((request: BroadcastMessage) => request.payload)
    );
  }

  // Dispatch an AppEvent with an optional payload
  dispatch<T>(event: AppEvent, payload: T = null): void {
    this.eventBrocker.next({event, payload});
  }
}