import { NgModule, APP_INITIALIZER, Injector, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';

import { MessageService } from 'primeng/api';

import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';

import AppInitializer from './bootstrap/services/app-initializer.service';
import { AppComponent } from './bootstrap/components/app/app';
import { ContextService } from './bootstrap/services/context.service';

import { AuthInterceptor } from './private/interceptors/auth-interceptor';
import { OneMissingTranslationHandler } from './shared/missing-translation-handler/one-missing-translation-handler';
import { OneErrorHandlerPrivate } from './private/handlers/one-error-handler-private';

import { OneHttpClient, OneHttpClientCreator } from './one-core/common/http/one-httpclient-factory';
import { DataSupplierInterceptor } from './private/interceptors/data-supplier-interceptor';
import { LanguageInterceptor } from './private/interceptors/LanguageInterceptor';
import { HttpErrorInterceptor } from './private/interceptors/HttpErrorInterceptor';


export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const oktaAuth = new OktaAuth({
  issuer: `${environment.oktaDomain}/oauth2/default`,
  clientId: environment.oktaClientId,
  redirectUri: `${environment.services.baseUri}/app/login/callback`,
  postLogoutRedirectUri: `${environment.services.baseUri}/app`,
});


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    OktaAuthModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: OneMissingTranslationHandler
      },
      useDefaultLang: true,
    }),
    
    AppRoutingModule,
  ],
  providers: [
    ContextService,
    {
      provide: OneHttpClient,
      useFactory: OneHttpClientCreator,
      deps: [HttpClient]
    }, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataSupplierInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (context: ContextService) => () => context.init(),
      deps: [ContextService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializer,
      deps: [Injector],
      multi: true
    },
    // {
    //   provide: ErrorHandler,
    //   useClass: OneErrorHandlerPrivate
    // },
    { 
      provide: OKTA_CONFIG,
      useValue: { oktaAuth }
    },
    MessageService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
